import React, { useEffect, useState } from "react";
import moment from 'moment';

function App() {
  const [data, setData] = useState([]);
  const [years, setYears] = useState([]);

  const getData = () => {
    fetch('data/calendar.json'
        ,{
          headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }
    ).then((response) => response.json()).then((json) => {
      setYears([...new Set(json.flatMap((item) => item.year))]);
      setData(json.sort((a, b) => {
        if (a.year !== b.year)
          return a.year - b.year;
        if (a.month !== b.month)
          return a.month - b.month;
        return a.day - b.day;
      }));
    });
  };

  const getItemsForMonth = (month, year) => {
    const monthNumber = parseInt(moment().locale('nl').month(month).format("M"));
    return data.filter((item) => item.year === parseInt(year) && item.month === monthNumber).map((item, i) => {
      if (i % 2 === 0) {
        return <div key={item.title} className='flex-row-reverse flex md:contents'>
          <div
              className={`${item.finished ? 'opacity-50' : ''} bg-black col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md`}
          >
            <h3 className="font-semibold text-lg mb-1" id={item.title.replace(" ", "-")}>{item.title}</h3>
            <ul className="leading-tight text-justify">
              <li>Verantwoordelijke: {item.responsible}</li>
              {item.achieved_for ?
                  <li>Gebeurt voor: <a className="underline" {... item.achieved_for ? {href: `#${item.achieved_for.replace(" ", "-")}`} : {}}>{item.achieved_for}</a>
                  </li> : ""}
            </ul>
          </div>
          <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
            <div className="h-full w-6 flex items-center justify-center">
              <div className="h-full w-1 bg-green-800 pointer-events-none"></div>
            </div>
            <div
                className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-green-500 shadow text-center"
            >{item.day}</div>
          </div>
        </div>
      } else {
        return <div id={item.title} key={item.title} className="flex md:contents">
          <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
            <div className="h-full w-6 flex items-center justify-center">
              <div className="h-full w-1 bg-green-800 pointer-events-none"></div>
            </div>
            <div
                className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-green-500 shadow text-center"
            >{item.day}</div>
          </div>
          <div
              className="bg-black col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md"
          >
            <h3 className="font-semibold text-lg mb-1" id={item.title.replace(" ", "-")}>{item.title}</h3>
            <ul className="leading-tight text-justify">
              <li>Verantwoordelijke: {item.responsible}</li>
              {item.achieved_for ?
                  <li>Gebeurt voor: <a className="underline" {... item.achieved_for ? {href: `#${item.achieved_for.replace(" ", "-")}`} : {}}>{item.achieved_for}</a>
                  </li> : ""}
            </ul>
          </div>
        </div>
      }
    })
  };

  const getMonthsFromYear = (year) => {
    return moment.months().filter((month, i) => year === 2021 ? i > 5 : true).map((month) => <><div className='flex-row-reverse flex md:contents'>
      <div
          className="col-start-1 col-end-5 p-4 my-2 ml-auto"
      >
        <h3 className="font-semibold text-lg mb-1 text-black">{month}</h3>
      </div>
      <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
        <div className="h-full w-6 flex items-center justify-center">
          <div className="h-full w-1 bg-green-800 pointer-events-none"></div>
        </div>
        <div
            className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-green-500 shadow"
        ></div>
      </div>
    </div>{getItemsForMonth(month, year)}</>)
  }

  useEffect(()=>{
    console.log(moment.locales());
    getData();
  },[]);

  return (
    <div className="App">
      <div className="container mx-auto">
        <div
            className="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-blue-50"
        >
          {
            years.map((year) => <>
                <div className='flex-row-reverse flex md:contents'>
                  <div
                      className="col-start-1 col-end-5 p-4 my-2 ml-auto"
                  >
                    <h3 className="font-semibold text-xl mb-1 text-black">{year}</h3>
                  </div>
                  <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
                    <div className="h-full w-6 flex items-center justify-center">
                      <div className="h-full w-1 bg-green-800 pointer-events-none"></div>
                    </div>
                    <div
                        className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-green-500 shadow"
                    ></div>
                  </div>
                </div>
                { getMonthsFromYear(year) }
            </>)
          }
        </div>
      </div>
    </div>
  );
}

export default App;
